/* LandingPage.css */
.title-visible {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out; /* Adjust the duration and easing as needed */
  }
  
  .title-hidden {
    opacity: 0;
    transform: translateY(-100%);
  }
  
  .landing-page{
    background-image: url('../public/background-pattern.png');
    min-width: 100vw;
    min-height: 100vh;
    max-width: 100vw;
max-height: 100vh;
flex-direction: column;
align-items: center;
justify-content: end;
bottom: 0;
position: absolute;
display: flex;
overflow: hidden;
  }

  .anchor-bottom-img{
    width: 100%;
    max-width: 984px;
    min-height: 402px;
    max-height: 402px;
    height: 100%;
  }

  .stars{
    margin-top: -50px;
    max-height: 88px;
    width: auto;
  }

  h1{
    color: #101828;
    font-weight: 600;
    font-size: 70px;
    margin-left: -120px;
  }
  
  .fixed-title{
    color: #101828;
    font-weight: 600;
    font-size: 120px;
    width: 100%;
    padding-left: 70px;
    line-height: normal;
    text-align: center;

  }

  .title-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  p{
    font-size: 20px;
    color: #475467;
    margin-bottom: 30px;
    text-align: center;
  }

  .metric-img{
    margin-right: -150px;
z-index: 1;
margin-top: -60px;
max-width: 301px;
width: 100%;
max-height: 363px;
height: 100%;

  }

  .large-img{
    max-width: 596px;
    position: absolute;
    left: calc((100% - 984px)/2 + 650px);
    margin-top: 100px;
  }

  .small-img{
    height: 124px;
    max-width: 240px;
    margin-left: 1000px;
    position: absolute;
  }
  @keyframes fadeInFromTop {
    from {
      opacity: 0;
      transform: translateY(-50%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeInFromRight {
    from {
      opacity: 0;
      transform: translateX(20%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .small-img-fade-in{
    animation: fadeInFromRight 2s ease backwards!important;
  }
  
  .title-fade-in {
    animation: fadeInFromTop 2s ease forwards!important;
  }

  .large-img-fade-in{
    animation: fadeInFromBottom 2s ease backwards!important;
  }

  .desc-fade-in{
    animation: fadeInFromBottom 2s ease backwards!important;
  }

  @keyframes fadeInFromBottom {
    from {
      opacity: 0;
      transform: translateY(50%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media(max-width: 1400px){
    .large-img {
      max-width: 450px;
    }
  }

  @media(max-width: 1024px){
    .large-img {
      max-width: 380px;
      }

      .fixed-title{
        font-size: 90px;
      }

      h1{
        font-size: 50px;
      }

      p{
        font-size: 16px;
      }

      .small-img{
        margin-left: 800px;
      }
  }

  @media(max-width: 900px){
    .large-img {
      max-width: 300px;
      margin-top: 80px;
      }

      h1{
        margin-left: -50px;
      }

      .anchor-bottom-img {
        height: 100%;
        max-height: 322px;
        width: auto;
        min-width: auto;
        }
  }

  @media(max-width: 800px){
   .stars{
    position: absolute;
    right: 20px;
margin-top: -70px;
   }

   .landing-page{
    justify-content: space-between;
   }

   .title-section{
    height: 100vh;
   }

   .small-img{
    position: absolute;
    right: 0;
    bottom: 30vh;
   }

   h1{
    margin-left: 0;
   }

   .large-img {
    max-width: 400px;
    margin-top: 0px;
    left: 50%;
    position: absolute;
    top: 20px;
    }
  }

  @media(max-width: 600px){
    .fixed-title {
      font-size: 70px;
      }

      .large-img{
        display: none;
      }

      .stars{
        max-height: 60px;
       }
  }


  @media(max-width: 480px){
    .fixed-title {
      font-size: 50px;
      padding-inline: 0!important;
      }

   .stars{
    max-height: 60px;
    top: 20px;
   }

   h1{
    font-size: 40px;
    text-align: center;
    height: 96px;
    min-height: 96px;
    max-height: 96px;
    display: flex;
align-items: end;
   }

   .title-section{
    line-height: 40px;
   }

   .anchor-bottom-img {
    height: 100%; 
    min-height: 176px;
    max-height: 176px;
    width: 100%;
    min-width: 100%;
    }
  }